var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, makeObservable, observable } from 'mobx';
import { estimateRates } from '../methods/order';
import { storage } from '../storage';
const MIN_UPDATE_TIME = 5000;
const MAX_UPDATE_TIME = 60000;
export class ExchangeController {
    constructor(estimateRequestBody, amount, smartContractData) {
        this.ttl = MIN_UPDATE_TIME;
        this.smartContractData = smartContractData;
        // @todo надо оставить только exchangeInfo
        this.fee = null;
        this.rate = null;
        this.timeout = undefined;
        this.exchangeInfo = null;
        this.error = false;
        this.exchangeDto = estimateRequestBody;
        this.amounts = amount;
        makeObservable(this, {
            amounts: observable,
            fee: observable,
            rate: observable,
            error: observable,
            exchangeInfo: observable,
            getRates: action,
        });
    }
    getRates() {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.timeout);
            try {
                const res = yield estimateRates(this.exchangeDto);
                const data = updateAmount(res.data);
                this.ttl = Math.min(Math.max(data.rateTtl * 1000, MIN_UPDATE_TIME), MAX_UPDATE_TIME);
                this.error = false;
                this.exchangeInfo = data;
                this.amounts = data.amounts;
                this.fee = data.fee;
                this.rate = data.rate;
            }
            catch (e) {
                this.error = true;
                throw e;
            }
            finally {
                this.timeout = setTimeout(() => {
                    this.getRates();
                }, this.ttl);
            }
        });
    }
    changeRequest(values) {
        return __awaiter(this, void 0, void 0, function* () {
            this.exchangeDto = getEstimateRequestDTOFromFields(values);
            clearTimeout(this.timeout);
            yield this.getRates();
            return {
                rate: this.rate,
                amounts: this.amounts,
                fee: this.fee,
            };
        });
    }
    startWatching() {
        this.getRates();
    }
    stopWatching() {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
}
export function getDefaultAmounts(defaultDto, currencies) {
    if (!defaultDto) {
        throw Error();
    }
    return {
        from: {
            currency: currencies.get(defaultDto.amounts.from.currency),
            amount: 'amount' in defaultDto.amounts.from
                ? `${defaultDto.amounts.from.amount}`
                : '0',
        },
        to: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            currency: currencies.get(defaultDto.amounts.to.currency),
            amount: 'amount' in defaultDto.amounts.to
                ? `${defaultDto.amounts.to.amount}`
                : '0',
        },
        isError: false,
    };
}
function getEstimateRequestDTOFromFields({ fromAmount, toAmount, fromCurrency, toCurrency, direction, }) {
    const amounts = direction === 'from'
        ? {
            from: { amount: +fromAmount, currency: fromCurrency.a3 },
            to: { currency: toCurrency.a3 },
        }
        : {
            from: { currency: fromCurrency.a3 },
            to: { currency: toCurrency.a3, amount: +toAmount },
        };
    return { amounts: amounts };
}
function updateAmount(data) {
    const toAmount = Number(data.amounts.to.amount);
    const finalToAmount = storage.PAYMENT_CHANNEL.get() === 'WIRE_TRANSFER'
        ? (toAmount * 0.97).toFixed(1)
        : toAmount.toString();
    return Object.assign(Object.assign({}, data), { amounts: Object.assign(Object.assign({}, data.amounts), { from: Object.assign(Object.assign({}, data.amounts.from), { amount: Number(data.amounts.from.amount).toString() }), to: Object.assign(Object.assign({}, data.amounts.to), { amount: finalToAmount }) }) });
}
export function createExchangeController(currencies, order, defaultAmount = 100, smartContractData, isEuro) {
    var _a;
    const defaultEstimateRequestBody = getDefaultEstimateRequestBody(currencies, order, defaultAmount, smartContractData, isEuro);
    const defaultAmounts = getDefaultAmounts(defaultEstimateRequestBody, currencies.currenciesMap);
    const defaultSmartContractData = smartContractData || ((_a = order === null || order === void 0 ? void 0 : order.metadata) === null || _a === void 0 ? void 0 : _a.smartContractData);
    return new ExchangeController(defaultEstimateRequestBody, defaultAmounts, defaultSmartContractData);
}
export function getDefaultEstimateRequestBody(currencies, order, defaultAmount, smartContractData, isEuro) {
    var _a, _b;
    const currencyCodeByGeo = isEuro ? 'EUR' : 'USD';
    const defaultFromCurrency = ((_a = currencies.currenciesMap.get(currencyCodeByGeo)) === null || _a === void 0 ? void 0 : _a.a3) ||
        currencies.from[0].a3;
    const defaultToCurrency = Array.isArray(currencies.to[0])
        ? currencies.to[0][0].a3
        : currencies.to[0].a3;
    if ((_b = order === null || order === void 0 ? void 0 : order.metadata) === null || _b === void 0 ? void 0 : _b.smartContractData) {
        return {
            amounts: {
                from: {
                    currency: order.amounts.from.currency.a3,
                },
                to: {
                    amount: +order.amounts.to.amount,
                },
            },
            metadata: order.metadata,
        };
    }
    if (order === null || order === void 0 ? void 0 : order.amounts) {
        return {
            amounts: {
                from: {
                    amount: +order.amounts.from.amount,
                    currency: order.amounts.from.currency.a3,
                },
                to: {
                    currency: order.amounts.to.currency.a3,
                },
            },
        };
    }
    if (smartContractData) {
        const amounts = {
            from: {
                currency: defaultFromCurrency,
            },
            to: {
                amount: 1,
            },
        };
        return {
            amounts,
            metadata: {
                smartContractData: Object.assign(Object.assign({}, smartContractData), { imageUrl: smartContractData.imgUrl, imageName: smartContractData.name }),
            },
        };
    }
    return {
        amounts: {
            from: {
                amount: defaultAmount,
                currency: defaultFromCurrency,
            },
            to: {
                currency: defaultToCurrency,
            },
        },
    };
}
