import Tracker from '@openreplay/tracker';
export const recording = new Tracker({
    projectKey: 'RFmEbYNQaRhRSUIGOaTf',
    obscureInputEmails: false,
    obscureTextNumbers: false,
    obscureInputDates: false,
    obscureInputNumbers: false,
    obscureTextEmails: false,
    network: {
        capturePayload: true,
        sessionTokenHeader: false,
        failuresOnly: false,
        ignoreHeaders: true,
        captureInIframes: false,
    },
});
